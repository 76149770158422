import React from "react";
import { WrapPageElementBrowserArgs } from "gatsby";
import { WrapRootElement } from "./src/providers";

export const wrapPageElement = ({
	element,
}: Pick<WrapPageElementBrowserArgs, "element">) => {
	return <WrapRootElement element={element} />;
};

/* export const onClientEntry = () => {
  const language = localStorage.getItem("gatsby-language");
  if (language) {
    document.documentElement.lang = language;
  }
};

export const onRouteUpdate = ({ location }) => {
  const path = location.pathname.split("/")[1];
  const language = languages.includes(path) ? path : defaultLanguage;

  if (!languages.includes(path)) {
    // Se il percorso non include una lingua, redirigi alla lingua di default
    const newUrl = `/${defaultLanguage}${location.pathname}`;
    window.location.replace(newUrl);
    return;
  }

  document.documentElement.lang = language;
  localStorage.setItem("gatsby-language", language);
};
 */
