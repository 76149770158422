import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	ReactNode,
} from "react";

type MainContextType = {
	//types
};

const MainContext = createContext<MainContextType>({
	//defaults
});

export const MainProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [windowWidth, setWindowWidth] = useState(0);
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		//starter to get window sizes without problems in SSR
		// Check if window is defined (this code runs only on the client side)
		if (typeof window !== "undefined") {
			const handleResize = () => {
				setWindowWidth(window.innerWidth);
				setIsMobile(window.innerWidth < 768);
			};

			// Initial width set
			handleResize();

			// Add event listener for resize
			window.addEventListener("resize", handleResize);

			// Cleanup event listener on unmount
			return () => {
				window.removeEventListener("resize", handleResize);
			};
		}
	}, []);

	//operazioni di fetch
	//axios etc..

	useEffect(() => {
		//on Start
	}, []);

	return (
		<MainContext.Provider
			value={{
				windowWidth,
				isMobile,
				/* your shared values */
			}}
		>
			{children}
		</MainContext.Provider>
	);
};

export const useMainContext = () => useContext(MainContext);
